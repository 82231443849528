<template>
  <div>
    <b-modal
      @hide="closeModal"
      id="details"
      size="lg"
      hide-footer
      centered
      :title="title"
    >
      <div class="content" v-if="item != null && !document && !support">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="title">Korisnik</div>
              <div class="content">{{ item.user.full_name }}</div>
            </div>
            <div class="col-12 col-md-3">
              <div class="title">Sektor</div>
              <div class="content">{{ item.user.sector.name }}</div>
            </div>
            <div class="col-12 col-md-3" v-if="!isnew">
              <div class="title">Oprema</div>
              <div class="content">
                {{ item.equipment_user.equipment.name }}
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="title">Datum</div>
              <div class="content">
                {{ item.created_at | moment("D.M.Y.") }}
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="title">Komentar</div>
              <div class="content">{{ item.notes }}</div>
            </div>
            <div class="col-6" v-if="item.comment">
              <div class="title">Komentar odbijanja</div>
              <div class="content">{{ item.comment }}</div>
            </div>
          </div>
          <div v-if="support" class="row mt-4">
            <qrcode-vue
              :value="`${baseurl}/equipment-document/${item.id}`"
              :size="100"
              foreground="#4A4A4A"
              level="H"
            ></qrcode-vue>
          </div>
        </div>
      </div>

      <div class="content" v-if="item != null && document">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="title">Oprema</div>
              <div class="content">{{ item.equipment }}</div>
            </div>
            <div class="col-12 col-md-4">
              <div class="title">Serijski broj</div>
              <div class="content">{{ item.serial_number }}</div>
            </div>
            <div class="col-12 col-md-4">
              <div class="title">Inventarski broj</div>
              <div class="content">
                {{ item.equipment_detail.inventory_number }}
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="title">Kategorija</div>
              <div class="content">{{ item.category }}</div>
            </div>
            <div class="col-12 col-md-4" v-if="item.user_detail !== null">
              <div class="title">Korisnik</div>
              <div class="content">{{ item.user }}</div>
            </div>
            <div class="col-12 col-md-4" v-if="item.user_detail !== null">
              <div class="title">Sektor</div>
              <div class="content">{{ item.user_detail.sector.name }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 mt-2">
              <div class="title">Preuzeto stanje</div>
              <div class="content wb-all">
                {{
                  !item.rented_state
                    ? "Nema preuzeto stanje"
                    : item.rented_state
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content" v-if="item != null && support">
        <div class="container">
          <div class="row">
            <div class="col-12 mt-2">
              <div class="title">Naziv</div>
              <div class="content">
                <b class="wb-all">{{ item.name }}</b>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Serijski broj</div>
              <div class="content wb-all">{{ item.serial_number }}</div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Inventarski broj</div>
              <div class="content wb-all">{{ item.inventory_number }}</div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Cijena</div>
              <div class="content">
                <i> {{ item.price ? `${item.price}€` : "Nema" }}</i>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Kategorija</div>
              <div class="content">{{ item.category.name }}</div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Potkategorija</div>
              <div class="content">{{ item.subcategory.name }}</div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Status opreme</div>
              <div class="content">{{ item.equipment_status.name }}</div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Dostupno</div>
              <div class="content">{{ !item.available ? "Ne" : "Da" }}</div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Zadužio</div>
              <div class="content">
                {{ !item.rented_to ? "Niko" : item.rented_to.user.full_name }}
              </div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Garancija</div>
              <div class="content" style="position: relative">
                {{
                  item.warranty_valid
                    ? "Validna"
                    : item.warranty === null
                    ? "Nema garanciju"
                    : "Istekla"
                }}
                <p
                  class="warranty-badge"
                  :class="{
                    'warranty-valid': item.warranty_valid,
                    'warranty-invalid': !item.warranty_valid,
                  }"
                >
                  {{ item.warranty | moment("D.M.Y.") }}
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-2">
              <div class="title">Datum kupovine</div>
              <div class="content" style="position: relative">
                <div v-if="item.purchased_at">
                  {{ item.purchased_at | moment("D.M.Y.") }}
                </div>
                <i v-else>Nema</i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <div class="title">Stanje</div>
              <div class="content wb-all">
                {{ !item.state ? "Nema stanje" : item.state }}
              </div>
            </div>
          </div>
          <div class="row" v-if="item.files.length > 0">
            <div class="col-12 mt-2">
              <div class="title">Fajlovi</div>
              <div class="row">
                <div
                  class="col-12 col-md-12 col-lg-6 col-xl-6 pl-0 mt-2"
                  v-for="f in item.files"
                  :key="f.id"
                >
                  <file :file="f"></file>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="row mt-4">-->
          <!--            <div class="title">Istorija korišćenja</div>-->
          <!--            <div class="content" v-if="loading"><b-spinner small ></b-spinner> Istorija korišćenja se učitava...</div>-->
          <!--            <div v-if="!loading">-->

          <!--              <div class="content" v-for="h in history.data" :key="h.id">-->
          <!--                <div class="row">-->
          <!--                  <div class="col-md-4 col-sm-6">{{ h.user }}</div>-->
          <!--                  <div class="col-md-4 col-sm-6">{{ h.rented_at }} - {{ h.deleted_at }}</div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="row mt-2" v-if="support">
            <div class="title">QR</div>
            <div class="h-100 relative">
              <div class="printOverlay">
                <div class="sm">
                  <p>Print</p>
                  <i class="fal fa-print" @click="printQr(0)"></i>
                </div>
              </div>
              <qrcode-vue
                class="qr"
                :value="`${baseurl}/equipment/${item.id}`"
                :size="200"
                colorDark="#4A4A4A"
                render-as="svg"
                level="H"
              ></qrcode-vue>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import File from "./File";
import { API_URL } from "../_plugins/axios";
import i18n from "../_plugins/i18n";
import QrcodeVue from "qrcode.vue";
export default {
  name: "DetailsModal",
  components: {
    File,
    QrcodeVue,
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_BASE_URL,
      loadingUnrent: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    title: {
      type: String,
      default: "Detalji zahtjeva",
    },
    isnew: {
      type: Boolean,
      default: false,
    },
    support: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Object,
    },
  },
  methods: {
    unrent(id) {
      this.loadingUnrent = true;
      API_URL.delete(`unrent-equipment/${id}`).then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.loadingUnrent = false;
        this.$bvModal.hide("details");
        this.$emit("unrented");
      });
    },
    imagetoPrint(source) {
      return (
        "<html><head><scri" +
        "pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" +
        "pt></head><body onload='step1()'>\n" +
        source +
        "</body></html>"
      );
    },

    printQr(size) {
      let height;
      switch (size) {
        case 0:
          height = 70;
          break;
        case 1:
          height = 120;
          break;
        case 2:
          height = 200;
          break;
      }
      let src = document.querySelector(".qr").innerHTML;

      console.log(src);

      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(this.imagetoPrint(src, height));
      pwa.document.close();

      //       const WinPrint = window.open(
      //         "",
      //         "",
      //         "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      //       );
      //
      //       WinPrint.document.write(`<!DOCTYPE html>
      // <html>
      //   <body>
      //     <img src="${src}" alt="" height="${height}" width="${height}">
      //   </body>
      // </html>`);
      //
      //       // WinPrint.document.close();
      //       WinPrint.focus();
      //       WinPrint.print();
      //       WinPrint.close();
    },
    closeModal() {
      this.$emit("modalClosed");
    },
  },
};
</script>

<style scoped lang="scss">
.printOverlay {
  opacity: 0;
  transition: 400ms all ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  gap: 20px;

  i {
    font-size: 2rem;
  }

  &:hover {
    opacity: 1;

    i {
      cursor: pointer;
    }
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.saveButton button {
  margin-top: 0.5rem;
  background-color: transparent;
  height: 44px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  float: right;
  border-radius: 30px;
  border: 2px solid $text_unrent;
  color: $text_unrent;
  font-family: Poppins, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  transition: 0.3s all ease-in-out;
}

.wb-all {
  word-break: break-all;
}
</style>
